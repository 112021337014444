import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';

import HeaderSeo from '../components/HeaderSeo';

import HeaderBackgroundDesktop from '../assets/img/api/UnlockGoGetDesktop.png';
import HeaderBackgroundMobile from '../assets/img/api/UnlockGoGetterMobile.png';
import FooterBackgroundDesktop from '../assets/img/api/FooterDesktop.png';
import FooterBackgroundMobile from '../assets/img/api/FooterMobile.png';

import TopHeader from '../views/shared/TopHeaderV2';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGogetV2';
import { AdvantagesOfGogetData } from '../views/APIPage/const/advantagesData';
import FaqData from '../views/APIPage/const/FaqData';
import ContactSection from '../components/ContactSection';
import FAQSection from '../views/shared/FAQ';
import UnlockFromGoGet from '../views/APIPage/UnlockFromGoGet';
import HowToGetStarted from '../views/APIPage/HowToGetStarted';

import { SharedBackground } from '../assets/sharedStyle';

import MetaImg from '../assets/img/meta/gogetters.png';
import { graphql } from 'gatsby';

const APIDesktopBanner = '../assets/img/api/banner-desktop.png';
const APIMobileBanner = '../assets/img/api/banner-mobile.png';

const BackgroundHeader = styled.div`
  ${SharedBackground};
  background-image: url(${HeaderBackgroundDesktop});
  @media (max-width: 760px) {
    background-image: url(${HeaderBackgroundMobile});
    padding-bottom: 4rem;
  }
`;
const BackgroundFooter = styled.div`
  ${SharedBackground};
  background-image: url(${FooterBackgroundDesktop});
  @media (max-width: 760px) {
    background-image: url(${FooterBackgroundMobile});
  }
`;
const BackgroundColour = styled.div`
  background: '#f0f8f7';
`;
const UnlockWrapper = styled.div`
  scroll-margin: 70px;
  @media (max-width: 760px) {
    scroll-margin: -10px;
  }
`;

const API = props => {
  const {
    data: { datoCmsApi }
  } = props;

  const ourCustomersRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    const behaviour = {
      behavior: 'smooth',
      block: 'start'
    };
    if (hash === 'ourCustomers') {
      ourCustomersRef.current.scrollIntoView(behaviour);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const apiBannerData = {
    title: 'Automate your deliveries',
    subTitle:
      'Enjoy integrated service, get live updates and eliminate human error.',
    h4: 'API Integration'
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Automate your deliveries"
        description="Enjoy integrated service, get live updates and eliminate human error."
        image={MetaImg}
        keywords=""
        pathname={props.location.pathname}
        schemaMarkup={datoCmsApi.schemaMarkup}
      />
      <Layout hideDownloadGoGetter>
        <TopHeader
          backgroundImg={
            <StaticImage
              src={APIDesktopBanner}
              loading="eager"
              style={{ height: '100%' }}
              quality={100}
              objectPosition="left top"
              placeholder="blurred"
              alt="GoGet API Integration"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={APIMobileBanner}
              loading="eager"
              style={{ height: '100%' }}
              imgStyle={{ height: '100%' }}
              objectFit="cover"
              quality={100}
              placeholder="blurred"
              alt="GoGet API Integration"
            />
          }
          title={apiBannerData.title}
          description={apiBannerData.subTitle}
          h4={apiBannerData.h4}
        />
        <BackgroundHeader>
          <BackgroundColour>
            <AdvantagesOfGoget data={AdvantagesOfGogetData} />
          </BackgroundColour>
          <UnlockWrapper ref={ourCustomersRef}>
            <UnlockFromGoGet />
          </UnlockWrapper>
        </BackgroundHeader>
        <BackgroundFooter>
          <HowToGetStarted />
          <FAQSection data={FaqData} />
          <ContactSection
            hideBackground
            title={<div> Let us help grow your business! </div>}
            subtext="Provide us with your details and we will get in touch."
            apiPage
          />
        </BackgroundFooter>
      </Layout>
    </>
  );
};

export default API;

export const query = graphql`
  query APIQuery {
    datoCmsApi {
      schemaMarkup
    }
  }
`;
