import React from 'react';
import styled from 'styled-components/macro';

import { Paper, Avatar } from '@material-ui/core';

import TitleHeaderText from '../../components/TitleHeaderText';
import { HowToGetStartedData } from './const/howToGetStartedData';

import colors from '../../assets/colors';

const Container = styled.div`
  color: ${colors.white};
  max-width: 1050px;
  margin: 0 auto;
  width: 90%;
  position: relative;
  margin-top: 6rem;
  padding-top: 1rem;
  @media only screen and (max-width: 760px) {
    padding-top: 1rem;
    padding-bottom: 5rem;
  }
`;
const StyledPaper = styled(Paper)`
  &.paper {
    box-shadow: 0px 4px 10px rgba(69, 69, 69, 0.3);
    border-radius: 20px;
    padding: 19px 24px;
    max-width: 543px;
    margin: 0px auto 40px;
  }
`;
const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAvatar = styled(Avatar)`
  &.number {
    width: 43px;
    height: 43px;
    background: ${colors.black};
    color: ${colors.white};
  }
`;
const Text = styled.p`
  font-size: 19.2px;
  color: ${colors.black};
  margin-left: 1rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  .teal {
    a {
      margin-left: 6px;
      color: ${colors.primary};
    }
  }
`;
const BodyContainer = styled.div`
  margin-top: 40px;
`;

const HowToGetStarted = () => {
  return (
    <Container>
      <TitleHeaderText text="How to get started" />
      <BodyContainer>
        {HowToGetStartedData.map(info => (
          <RenderBox key={info.number} number={info.number} text={info.text} />
        ))}
      </BodyContainer>
    </Container>
  );
};

const RenderBox = props => {
  const { number, text } = props;
  return (
    <StyledPaper className="paper">
      <InnerWrapper>
        <StyledAvatar className="number">{number}</StyledAvatar>
        <Text>{text}</Text>
      </InnerWrapper>
    </StyledPaper>
  );
};

export default HowToGetStarted;
