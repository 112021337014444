import React from 'react';
export const HowToGetStartedData = [
  {
    number: '1',
    text: (
      <>
        Check out our API
        <span className="teal">
          <a
            href="https://api-docs.goget.my/"
            style={{ textDecoration: 'none' }}
          >
            documentation.
          </a>
        </span>
      </>
    )
  },
  {
    number: '2',
    text: 'Fill up the form below.'
  },

  {
    number: '3',
    text: 'Our team will get in touch.'
  }
];
