import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import UnlockCard from '../../views/shared/UnlockCard';
import {
  lazada,
  beep,
  racunTech,
  bloomThis,
  secretRecipe,
  yoodo
} from './const/unlockGoGetData';
import Slider from '../../views/LandingPage/Slider';

import colors from '../../assets/colors';

const MainDiv = styled.div`
  display: ${props => !props.scroll && 'flex'};
  justify-content: center;
  flex-wrap: wrap;
  width: ${props => (!props.scroll ? '100%' : '1050px')};
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-page-dots {
    display: none;
    @media (max-width: 1155px) {
      display: block;
    }
  }
  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;

const UnlockFromGoGet = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  let unlockData = [];
  if (isMobile) {
    unlockData = [yoodo, lazada, beep, racunTech, bloomThis, secretRecipe];
  } else {
    unlockData = [lazada, beep, racunTech, bloomThis, secretRecipe, yoodo];
  }

  const options = {
    // autoPlay: 4000,
    // pauseAutoPlayOnHover: true,
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: true
  };
  return (
    <MainDiv scroll={true}>
      <TitleHeaderText
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
        text="Unlock more from GoGet"
      />
      <Slider options={options}>
        {unlockData &&
          unlockData.map(data => {
            return <UnlockCard key={data.id} data={data} />;
          })}
      </Slider>
    </MainDiv>
  );
};

export default UnlockFromGoGet;
