import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const BeepImage = './assets/Beep.png';
const BloomThisImage = './assets/BloomThis.png';
const LazadaImage = './assets/Lazada.png';
const RacunTechImage = './assets/RacunTech.png';
const SecretRecipeImage = './assets/SecretRecipe.png';
const YoodoImage = './assets/Yoodo.png';

const lazada = {
  id: 1,
  image: (
    <StaticImage
      src={LazadaImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="Lazada"
    />
  ),
  title: (
    <>
      <strong>Lazada,</strong> E-commerce marketplace{' '}
    </>
  ),
  number: '99.71%',
  subTitle: 'Successful deliveries',
  description: (
    <>
      GoGet provides point to point fresh grocery deliveries for Lazada sellers.
      <br /> <br />
      Sellers enjoy 24 hour deliveries at their fingertips with the ease of
      integrations.
    </>
  ),
  url: 'https://sellercenter.lazada.com.my/apps/register/index?spm=a2o4k.11358950.8903921690.1.76b822a6mfHru9&registerVersion=2.0&lazMall=0&scm=1003.4.icms-zebra-5010399-3775052.OTHER_6035062880_6929903'
};
const beep = {
  id: 2,
  image: (
    <StaticImage
      src={BeepImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="StoreHub Beep"
    />
  ),
  title: (
    <>
      <strong>Beep,</strong> Food delivery{' '}
    </>
  ),
  number: '1.2K local businesses',
  subTitle: 'Helped',
  description: (
    <>
      Enable on demand delivery for the marketplace with thousands of
      restaurants.
      <br /> <br />
      Helping businesses to expand extra sales channel without increasing cost.
    </>
  ),
  url: 'https://beepit.com/'
};
const racunTech = {
  id: 3,
  image: (
    <StaticImage
      src={RacunTechImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="RacunTech"
    />
  ),
  title: (
    <>
      <strong>RacunTech,</strong> Customised Computer Sales & Trading{' '}
    </>
  ),
  number: '415',
  subTitle: 'Total deliveries since integration',
  description: (
    <>
      Automate pick up from multiple locations.
      <br /> <br />
      Great for businesses with multiple outlets and offices.
    </>
  ),
  url: 'https://racuntech.com/'
};
const bloomThis = {
  id: 4,
  image: (
    <StaticImage
      src={BloomThisImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="BloomThis"
    />
  ),
  title: (
    <>
      <strong>BloomThis,</strong> Florist{' '}
    </>
  ),
  number: '3 cities',
  subTitle: 'Deliveries in KV, PG and JB',
  description: (
    <>
      With seamless API integrations, it is easier to ramp up delivery
      especially during peak season.
      <br />
      <br />
      Aside from day to day delivery, the integration of deliveries has help to
      make the roll out across 3 cities seamlessly.
    </>
  ),
  url: 'https://bit.ly/2DcDaTt'
};
const secretRecipe = {
  id: 5,
  image: (
    <StaticImage
      src={SecretRecipeImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="Secret Recipe"
    />
  ),
  title: (
    <>
      <strong>Secret Recipe,</strong> F&B{' '}
    </>
  ),
  number: '456',
  subTitle: 'GoGetters hired',
  description: (
    <>
      Save on overhead costs with fully customisable deliveries.
      <br /> <br />
      The seamless experience of the API integration delivery allows you the
      flexibility to set your own delivery window to coverage area.
    </>
  ),
  url: 'https://www.secretrecipe.com.my/cake-delivery/'
};
const yoodo = {
  id: 6,
  image: (
    <StaticImage
      src={YoodoImage}
      placeholder="blurred"
      style={{ height: '56px', marginBottom: '28px' }}
      objectFit="contain"
      alt="Yoodo"
    />
  ),
  title: (
    <>
      <strong>Yoodo,</strong> Telecommunications{' '}
    </>
  ),
  number: '8 mins',
  subTitle: 'Fastest delivery time',
  description: (
    <>
      Malaysia’s first truly customisable and 100% digital telco.
      <br /> <br />
      SIM cards are delivered on demand to customers without having to hire
      their own fleet of full timers.
    </>
  ),
  url: 'https://www.yoodo.com.my/'
};

export { lazada, beep, racunTech, bloomThis, secretRecipe, yoodo };
