import React from 'react';
import styled from 'styled-components/macro';

import { Paper } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';

import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const StyledPaper = styled(Paper)`
  &.paper {
    background: ${colors.white};
    box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
    border-radius: 20px;
    padding: 32px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 375px;
    width: 282px;
    align-items: center;

    margin: 0px 30px 20px 0px;
  }
`;
const Title = styled.h4`
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  font-weight: 400;
`;
const Number = styled.div`
  font-size: 21px;
  color: ${colors.secondary};
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 11px;
`;
const Description = styled.p`
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 19px;
  margin-top: 21px;
`;
const SubTitle = styled.p`
  font-size: ${fontSize.medium};
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
`;
const ArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${colors.primary};
  margin-left: 1px;
`;
const Website = styled.a`
  color: ${colors.primary};
  font-size: ${fontSize.large};
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
`;

const UnlockCard = props => {
  const { data } = props;
  return (
    <StyledPaper className="paper">
      {data && data.image}
      <Title>{data && data.title}</Title>
      <Number>{data && data.number}</Number>
      <SubTitle>{data && data.subTitle}</SubTitle>
      <Description>{data && data.description}</Description>
      <Footer>
        <Website href={data && data.url}>VISIT WEBSITE</Website>
        <ArrowRightIcon />
      </Footer>
    </StyledPaper>
  );
};

export default UnlockCard;
