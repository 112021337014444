import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const FullyCustomisable = './assets/FullyCustomisable.png';
const Integrate = './assets/Integrate.png';
const ProvideDelivery = './assets/ProvideDelivery.png';

export const AdvantagesOfGogetData = [
  {
    img: (
      <StaticImage
        src={ProvideDelivery}
        placeholder="blurred"
        loading="eager"
        alt="Provide on demand delivery"
      />
    ),
    header: ' Provide on demand delivery',
    desc: 'Give your customers amazing delivery experiences.'
  },
  {
    img: (
      <StaticImage
        src={Integrate}
        placeholder="blurred"
        loading="eager"
        alt="Integrate for free"
      />
    ),
    header: 'Integrate for free',
    desc: 'Connect your orders to GoGet instantly at absolutely no costs.'
  },
  {
    img: (
      <StaticImage
        src={FullyCustomisable}
        placeholder="blurred"
        loading="eager"
        alt="Fully customisable"
      />
    ),
    header: 'Fully customisable',
    desc: 'No minimum orders needed and select your own vehicle type.'
  }
];
